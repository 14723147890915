

































































import Vue from 'vue';
import UserLoginModel from '@glittr/frontend-core/src/services/viewModel/resource/TokenRequestViewModel';

export default Vue.extend({
  auth: false,
  layout: 'layout-exterior',
  data: () => ({
    login: new UserLoginModel(),
    isLoading: false,
    requestError: undefined as any,
  }),
  computed: {
    redirectPath(): string {
      return this.$route.query.redirect as string;
    },
  },
  mounted() {
    this.loadQRData();
  },
  methods: {
    loadQRData() {
      const query = this.$router.currentRoute?.query ?? {};
      const { email } = query as Record<string, string>;
      this.login.userName = email;
    },
    gotoRegister() {
      this.$router.push('/_exterior/register');
    },
    focusOnPassword() {
      const { passwordField } = this.$refs;
      const inputField = (passwordField as Vue).$refs.input;
      (inputField as HTMLElement).focus();
    },
    async doLogin() {
      if (!this.login.validate()) {
        return;
      }
      this.isLoading = true;
      this.requestError = undefined;
      this.$auth.login({ username: this.login.userName, password: this.login.password })
        .then(() => {
          this.isLoading = false;
          if (this.$auth.user!.statusId === 0) {
            this.$router.push('/_exterior/verify-user/');
          } else if (this.redirectPath) {
            this.$router.push(this.redirectPath);
          } else if (this.$auth.isInRole('hostadmin')) {
            this.$router.push('/_hostadmin/');
          } else {
            this.$router.push('/gs/memberPortal');
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error && error.status === 401) {
            this.requestError = { message: this.$t('page.login.label.wrongLogin') };
          } else {
            this.requestError = error;
          }
        });
    },
  },
});
